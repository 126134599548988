<!-- eslint-disable max-len -->
<template>
  <svg width="5" height="16" viewBox="0 0 5 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4499_7493)">
      <path
        d="M0.6135 15.173C-0.2045 14.355 -0.2045 13.027 0.6135 12.209C1.4315 11.391 2.7595 11.391 3.5775 12.209C4.3955 13.027 4.3955 14.355 3.5775 15.173C2.7595 15.991 1.4315 15.991 0.6135 15.173ZM0.7685 9.953L0.1045 4.424V0H4.0855V4.424L3.4215 9.954H0.7685V9.953Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4499_7493">
        <rect width="4.191" height="15.787" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>
