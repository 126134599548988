<!-- eslint-disable max-len -->
<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4499_7491)">
      <path
        d="M12.294 1.768L10.526 0L6.147 4.379L1.768 0L0 1.768L4.379 6.147L0 10.526L1.768 12.294L6.147 7.914L10.526 12.294L12.294 10.526L7.915 6.147L12.294 1.768Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4499_7491">
        <rect width="12.294" height="12.294" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>
