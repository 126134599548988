import { useI18n } from 'vue-i18n'

export function useQA() {
  const { locale } = useI18n()
  const toast = useToast()

  const notImplemented = () => {
    const message =
      locale.value === 'en'
        ? 'This feature is not ready for QA yet'
        : "Cette fonctionnalité n'est pas encore prête pour le QA"

    toast.add({
      severity: 'info',
      summary: 'QA',
      detail: message,
      life: 4000,
      group: 'custom-toast'
    })
  }

  return {
    notImplemented
  }
}
