import { useMFILogger } from '~/composables/logger'

export const useSkin = () => {
  const hasSkin = useState('hasSkin', () => false)
  const isDisabled = useState('skinDisabled', () => false)
  const { logger } = useMFILogger()
  onMounted(() => {
    try {
      initializeAds()
      logger.success('Initialize ads (SSR)')
    } catch (e) {
      logger.error('Error initializing ads (SSR)', e)
    }

    if (typeof window !== 'undefined') {
      window.enableSkin = () => {
        if (!isDisabled.value) {
          logger.success('Skin enabled')
          hasSkin.value = true
        } else {
          logger.warn('Unable to enable skin, it is disabled')
        }
      }

      window.disableSkin = () => {
        logger.success('Skin disabled')
        hasSkin.value = false
      }
    }
  })

  function toggleSkin() {
    if (typeof window !== 'undefined') {
      if (hasSkin.value) {
        window.disableSkin()
        const skinAdEl = document.querySelector('#adslot_skin_1')
        skinAdEl?.remove()
        isDisabled.value = true
      } else {
        isDisabled.value = false
        window.enableSkin()
      }
    }
  }

  return { toggleSkin, hasSkin, isDisabled }
}
