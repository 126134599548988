<!-- eslint-disable max-len -->
<template>
  <svg
    :class="props.class"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="4" height="4" stroke="currentColor" />
    <rect x="7.5" y="0.5" width="4" height="4" stroke="currentColor" />
    <rect x="0.5" y="7.5" width="4" height="4" stroke="currentColor" />
    <rect x="7.5" y="7.5" width="4" height="4" stroke="currentColor" />
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>
