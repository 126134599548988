<!-- eslint-disable max-len -->
<template>
  <svg
    :class="props.class"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1347_19684)">
      <path
        d="M6.67996 5C5.59996 5 4.70996 5.9 4.70996 7C4.70996 8.1 5.58996 9 6.67996 9C7.76996 9 8.64996 8.1 8.64996 7C8.64996 5.9 7.76996 5 6.67996 5Z"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M7.90981 13.5H5.44981C5.32981 13.5 5.21981 13.41 5.20981 13.28L5.01981 11.7C4.46981 11.5 3.95981 11.21 3.50981 10.82L2.06981 11.44C1.95981 11.49 1.81981 11.44 1.75981 11.33L0.529809 9.15998C0.469809 9.04998 0.499809 8.90998 0.599809 8.83998L1.84981 7.87998C1.79981 7.58998 1.76981 7.29998 1.76981 6.98998C1.76981 6.67998 1.79981 6.38998 1.84981 6.09998L0.599809 5.13998C0.499809 5.05998 0.469809 4.91998 0.529809 4.81998L1.75981 2.64998C1.81981 2.53998 1.94981 2.49998 2.06981 2.53998L3.50981 3.15998C3.95981 2.76998 4.46981 2.47998 5.01981 2.27998L5.20981 0.69998C5.22981 0.56998 5.32981 0.47998 5.44981 0.47998H7.90981C8.02981 0.47998 8.13981 0.56998 8.14981 0.69998L8.33981 2.27998C8.88981 2.47998 9.39981 2.76998 9.84981 3.15998L11.2898 2.53998C11.3998 2.48998 11.5398 2.53998 11.5998 2.64998L12.8298 4.81998C12.8898 4.92998 12.8598 5.06998 12.7598 5.13998L11.5098 6.09998C11.5598 6.38998 11.5898 6.68998 11.5898 6.97998C11.5898 7.26998 11.5598 7.57998 11.5098 7.85998L12.7598 8.81998C12.8598 8.89998 12.8898 9.03998 12.8298 9.13998L11.5998 11.31C11.5398 11.42 11.4098 11.46 11.2898 11.42L9.84981 10.8C9.39981 11.19 8.88981 11.48 8.33981 11.68L8.14981 13.26C8.12981 13.39 8.02981 13.48 7.90981 13.48V13.5Z"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1347_19684">
        <rect width="13.37" height="14" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>
