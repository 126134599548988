import type { Static } from '@sinclair/typebox'
import type { MfiAPI, sharedHelpers } from '@hubelia/mfiapi'

export async function fetchMenu(params: { locale: string; sdk: MfiAPI }) {
  const { locale, sdk } = params

  return await sdk.v1.mfi.menu
    .retrieve({ locale }, {})
    .then((res) => res.data as Static<(typeof sharedHelpers)['MappedMFIMenuSchema']>)
}
