export function usePageLocaleHead() {
  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true
  })

  const filteredHead = computed(() => {
    const pageName = usePageName()

    let links = head.value.link?.filter((l) => {
      return !(l.rel === 'alternate' && l.hreflang)
    })

    if (pageName === 'films list') {
      links = [
        ...(links?.map((link) => {
          if (link.rel === 'canonical') {
            return {
              ...link,
              href: `${link.href}?rt=in_theater`
            }
          } else {
            return link
          }
        }) || [])
      ]
    }

    return {
      ...head.value,
      link: links,
      meta: head.value.meta?.filter((m) => {
        return m.property !== 'og:locale:alternate'
      })
    }
  })

  return {
    head: filteredHead
  }
}
