<!-- eslint-disable max-len -->
<template>
  <svg :class="props.class" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path
          d="M8.13473 15.2695C12.0751 15.2695 15.2695 12.0751 15.2695 8.13473C15.2695 4.19433 12.0751 1 8.13473 1C4.19433 1 1 4.19433 1 8.13473C1 12.0751 4.19433 15.2695 8.13473 15.2695Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M13.1799 13.1799L16.7419 16.7419"
          stroke="currentColor"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>
