<!-- eslint-disable max-len -->
<template>
  <svg
    :class="props.class"
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1347_19675)">
      <path d="M8.75977 5.5H0.759766" stroke="currentColor" stroke-miterlimit="10" />
      <path
        d="M2.95996 7.78002L0.709961 5.53002L2.95996 3.27002"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M5.25977 8V10.5H12.2598V0.5H5.25977V3"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1347_19675">
        <rect width="12.76" height="11" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>
