<template>
  <svg
    :class="props.class"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="10.25 11.5 19.5 15.5"
  >
    <line x1="11" y1="12.25" x2="29" y2="12.25" stroke="currentColor" stroke-width="1.5"></line>
    <line x1="11" y1="19.25" x2="29" y2="19.25" stroke="currentColor" stroke-width="1.5"></line>
    <line x1="11" y1="26.25" x2="29" y2="26.25" stroke="currentColor" stroke-width="1.5"></line>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>
