<template>
  <div>
    <Toast position="top-right" group="custom-toast" @close="onClose">
      <template #message="slotProps">
        <div class="flex flex-column items-center gap-3" style="flex: 1">
          <div class="flex items-center gap-2 justify-center">
            <div
              :class="[
                'w-10 h-10 rounded-full flex justify-center !font-bold  items-center text-black',
                slotProps.message.severity === 'error'
                  ? 'bg-[#DC2627]'
                  : slotProps.message.severity === 'success'
                    ? 'bg-[#1A8956]'
                    : 'bg-[#FFC425]'
              ]"
            >
              <ErrorIcon
                v-if="slotProps.message.severity === 'error'"
                class="pi pi-times"
              ></ErrorIcon>
              <CheckIcon
                v-if="slotProps.message.severity === 'success'"
                class="pi pi-check font-bold"
              ></CheckIcon>
              <ExclamationIcon v-if="slotProps.message.severity === 'warn'"></ExclamationIcon>
            </div>
          </div>
          <div class="flex flex-col text-lg">
            <span class="text-black text-lg font-medium">{{ slotProps.message.summary }}</span>
            <span class="text-base text-[#454545]">{{ slotProps.message.detail }}</span>
          </div>
          ,
        </div>
      </template>
    </Toast>
  </div>
</template>
<script setup lang="ts">
import Toast from 'primevue/toast'
import ExclamationIcon from './icons/exclamation-icon.vue'
import ErrorIcon from './icons/error-icon.vue'
import CheckIcon from './icons/check-icon.vue'

const visible = ref(false)

const onClose = () => {
  visible.value = false
}
</script>
<style scoped></style>
