<!-- eslint-disable max-len -->
<template>
  <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.184 10.832L0 5.648L1.768 3.88L5.184 7.297L12.48 0L14.248 1.768L5.184 10.832Z"
      fill="black"
    />
  </svg>
</template>

<script setup lang="ts"></script>
