<!-- eslint-disable max-len -->
<template>
  <svg :class="props.class" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.4839 16.6334C16.787 15.7531 15.7148 15.1868 14.5172 15.1868H9.48578C8.28824 15.1868 7.21603 15.7531 6.51905 16.6334C6.073 17.1967 6.11535 18.0096 6.61945 18.5207C6.62201 18.5233 6.62458 18.5259 6.62714 18.5285C8.05766 19.971 9.97882 20.7725 12.0015 20.7725C14.0241 20.7725 15.9453 19.971 17.3758 18.5285C17.3784 18.5259 17.381 18.5233 17.3835 18.5207C17.8876 18.0096 17.93 17.1967 17.4839 16.6334H17.4839Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0016 7.15942C13.9868 7.15942 15.5966 8.78304 15.5966 10.7853C15.5966 12.7875 13.9868 14.4111 12.0016 14.4111C10.0165 14.4111 8.40674 12.7875 8.40674 10.7853C8.40674 8.78304 10.0165 7.15942 12.0016 7.15942Z"
          fill="currentColor"
        />
      </g>
      <path
        d="M23.25 12.9898C23.25 19.203 18.2132 24.2398 12 24.2398C5.7868 24.2398 0.75 19.203 0.75 12.9898C0.75 6.77657 5.7868 1.73977 12 1.73977C18.2132 1.73977 23.25 6.77657 23.25 12.9898Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>
